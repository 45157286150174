import favicon16 from './favicon16.png'
import favicon32 from './favicon32.png'
import favicon128 from './favicon128.png'
import favicon152 from './favicon152.png'
import favicon167 from './favicon167.png'
import favicon180 from './favicon180.png'
import favicon192 from './favicon192.png'
import favicon196 from './favicon196.png'
import favicon512 from './favicon512.png'

interface ImageList {
	favicon16: any
	favicon32: any
	favicon128: any
	favicon152: any
	favicon167: any
	favicon180: any
	favicon192: any
	favicon196: any
	favicon512: any
	[key: string]: any
}

export const favicon: ImageList = {
	favicon16,
	favicon32,
	favicon128,
	favicon152,
	favicon167,
	favicon180,
	favicon192,
	favicon196,
	favicon512
}
