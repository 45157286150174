interface AdUnit {
	name: string
	sizes: Readonly<Readonly<number[]>[]>
	shouldRefresh?: boolean
}

interface AdConfig {
	networkId: string
	unit: {
		articleBillboard: AdUnit
		articleBillboardMobile: AdUnit
		homepageBillboardDesktop: AdUnit
		homepageBillboardMobile: AdUnit
		homepageBottomDesktop: AdUnit
		homepageBottomMobile: AdUnit
		magazinePageDesktop: AdUnit
		magazinePageMobile: AdUnit
		rotationMedRec1: AdUnit
		rotationMedRec2: AdUnit
	}
}

export const ads: AdConfig = Object.freeze({
	networkId: '3004818',
	unit: Object.freeze({
		articleBillboard: Object.freeze({
			name: 'DSC-Billboard-Article',
			sizes: Object.freeze([
				Object.freeze([970, 250]),
				Object.freeze([970, 90])
			])
		}),
		articleBillboardMobile: Object.freeze({
			name: 'DSC-Billboard-Article-Mobile',
			sizes: Object.freeze([Object.freeze([300, 250])])
		}),
		homepageBillboardDesktop: Object.freeze({
			name: 'DSC-Billboard-HP',
			sizes: Object.freeze([Object.freeze([970, 250])])
		}),
		homepageBillboardMobile: Object.freeze({
			name: 'DSC-HP-Billboard-Mobile',
			sizes: Object.freeze([Object.freeze([300, 250])])
		}),
		homepageBottomDesktop: Object.freeze({
			name: 'DSC-BottomLeaderboard-HP',
			sizes: Object.freeze([Object.freeze([728, 90]), Object.freeze([970, 90])])
		}),
		homepageBottomMobile: Object.freeze({
			name: 'DSC-Bottom-HP-Leaderboard-Mobile',
			sizes: Object.freeze([Object.freeze([320, 50])])
		}),
		magazinePageDesktop: Object.freeze({
			name: 'DSC-Billboard-Article',
			sizes: Object.freeze([
				Object.freeze([970, 250]),
				Object.freeze([970, 90])
			])
		}),
		magazinePageMobile: Object.freeze({
			name: 'DSC-Billboard-Article-Mobile',
			sizes: Object.freeze([Object.freeze([300, 250])])
		}),
		rotationMedRec1: Object.freeze({
			name: 'DSC-MedRec1',
			sizes: Object.freeze([Object.freeze([300, 250])])
		}),
		rotationMedRec2: Object.freeze({
			name: 'DSC-MedRec2',
			sizes: Object.freeze([Object.freeze([300, 250])])
		})
	})
})
