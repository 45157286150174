import Axios from 'axios'

interface ApiResponse<T = unknown | undefined> {
	error: boolean
	status: number
	data?: T
}

export async function sendRequest<T>(
	method: 'get' | 'delete' | 'post' | 'put',
	url: string,
	data?: Record<string, unknown>
): Promise<ApiResponse<T>> {
	const request = await Axios.request({
		method,
		url,
		data
	}).catch((err) => err)

	const error = request.isAxiosError

	const res = error ? request.response : request

	return {
		error,
		status: res ? res.status : 500,
		data: res ? res.data : undefined
	}
}
