interface Fonts {
	[key: string]: {
		familyOnly: string
		weight: {
			[key: string]: string
		}
	}
}

/**
 * Parses fonts from the provided list of fonts and generates a Google Fonts URL
 */
export function getGoogleFontsUrl(fonts: Fonts): string {
	let familyString = ''

	Object.values(fonts).forEach((font, i) => {
		if (i !== 0) familyString += '&' // Font Family separator

		familyString += 'family=' + font.familyOnly.replace(' ', '+')

		const weights = Object.values(font.weight)
		if (weights.length) familyString += ':wght@' + weights.join(';')
	})

	return `https://fonts.googleapis.com/css2?${familyString}&display=swap`
}
