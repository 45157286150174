import { sendRequest } from '../../util'

import type { RegistrationInfo } from '~/types/app'

/**
 * Register User
 */
export async function register(data: RegistrationInfo): Promise<true | number> {
	// Make api request
	const result = await sendRequest(
		'post',
		'/api/auth/register',
		(data as unknown) as Record<string, unknown>
	)

	// Return boolean
	return result.error ? result.status : true
}
