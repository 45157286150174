//----- Definitions -----//

enum Provider {
	YOUTUBE,
	VIMEO,
	OTHER
}

enum BaseURL {
	YOUTUBE = 'https://www.youtube.com/embed/',
	VIMEO = 'https://player.vimeo.com/video/'
}

//----- Logic -----//

/**
 * Determines which provider the given URL is targeting
 */
function getProvider(url: string): number {
	if (/(https?:\/\/)?(www.)?youtu(.?)be(.com)?/.test(url))
		return Provider.YOUTUBE

	if (/(https?:)?\/\/(player.)?vimeo.com/.test(url)) return Provider.VIMEO

	return Provider.OTHER
}

/**
 * Extracts the video ID from various YouTube URL formats.
 * Returns null if no ID is found.
 */
function getYouTubeUrl(url: string): string | null {
	// Example: 'https://www.youtube.com/embed/<ID>?extra=params#t=<timestamp>'
	const filterRegExp = /(?:[?&]v=|\/embed\/|\/1\/|\/v\/|https?:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)/
	const foundMatch = url.match(filterRegExp)

	return foundMatch ? `${BaseURL.YOUTUBE}${foundMatch[1]}` : null
}

/**
 * Extracts the video ID from various Vimeo URL formats.
 * Returns null if no ID is found.
 */
function getVimeoUrl(url: string): string | null {
	// Example: 'https://player.vimeo.com/video/<ID>?extra=params'
	const filterRegExp = /(?:\/video\/)([^\n?#]+)/
	let foundMatch = url.match(filterRegExp)

	if (!foundMatch) {
		// Example: 'https://vimeo.com/<ID>'
		foundMatch = url.match(/(?:\/vimeo.com\/)([^\n?#]+)/)
	}

	return foundMatch ? `${BaseURL.VIMEO}${foundMatch[1]}` : null
}

//----- Entry Point -----//

/**
 * Converts the provided URL into an embeddable URL for YouTube or Vimeo.
 * Returns null if no embeddable URL is available.
 */
export function getVideoEmbedUrl(url?: string): string | null {
	if (!url) return null

	const provider = getProvider(url)

	switch (provider) {
		case Provider.YOUTUBE:
			return getYouTubeUrl(url)

		case Provider.VIMEO:
			return getVimeoUrl(url)
	}

	return null
}
