export const siteInfo = Object.freeze({
	kservDomain: 'DSC',
	rootUrl: 'https://www.discovermagazine.com',
	launchTime: '2019-11-04T20:33:16.000Z',
	publisher: 'Kalmbach Media Co.',
	title: 'Discover Magazine',
	tagline: 'Science That Matters',
	splashText: 'Stay Curious',
	description:
		'Discover satisfies everyday curiosity with relevant and ' +
		'approachable science news, feature articles, photos and more.',
	magazineDescription:
		'Discover magazine reports captivating developments in science, ' +
		'medicine, technology, and the world around us. Spectacular ' +
		'photography and refreshingly understandable stories on complex ' +
		'subjects connect everyday people with the greatest ideas and minds ' +
		'in science.'
})
