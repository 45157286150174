import Head from 'next/head'

import { favicon } from '~/static/assets/favicon'

function getSizes(name: string): string {
	const size = name.replace('favicon', '')

	return `${size}x${size}`
}

export const Favicons: React.FC = () => (
	<Head>
		{Object.keys(favicon).map((icon) => (
			<link
				key={`icon-${icon}`}
				rel="icon"
				href={favicon[icon]}
				sizes={getSizes(icon)}
			/>
		))}
	</Head>
)
