export const externalUrls = Object.freeze({
	existingSubscriber: 'https://ssl.drgnetwork.com/kmb/cs/gidDSALL/login',
	newsletterUnsubscribe:
		'https://apps.kalmbach.com/newsletters/Unsubscribe.aspx',
	newsletterChangeEmail:
		'https://apps.kalmbach.com/newsletters/ChangeEmail.aspx',
	privacyPolicy: 'https://www.kalmbach.com/privacy/',
	resetPassword:
		'https://ssl.drgnetwork.com/ecom/kmb/app/live/web_user_maint?pagemode=c&org=KMB',
	subscribe: 'https://ssl.drgnetwork.com/flex/KMB/DSCHOICE/',
	subscribeMeterBanner:
		'https://ssl.drgnetwork.com/flex/KMB/DSCHOICE/?key1=DSF13D2&key2=ACF13D2',
	subscribePaywallPrompt:
		'https://ssl.drgnetwork.com/flex/KMB/DSCHOICE/?key1=DSF13D3&key2=ACF13D3',
	termsOfUse: 'https://www.kalmbach.com/termsofuse/'
})
