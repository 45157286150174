import { css, FlattenInterpolation, ThemeProps } from 'styled-components'
import { transparentize } from 'polished'

/**
 * Returns a box-shadow property based on the supplied color string
 */
export const glow = (
	color: string
): FlattenInterpolation<ThemeProps<any>> => css`
	box-shadow: 0 0 10px 0px ${color},
		0 0 3px 0px ${(p) => transparentize(0.5, p.theme.color.primary.contrast)};
`
