import { useRef, useEffect, MutableRefObject } from 'react'

// For SSR, this will be false
const isBrowser = typeof window !== 'undefined'

interface Coordinates {
	x: number
	y: number
}

interface ScrollConfig {
	effect: (newCoords: Coordinates, previousCoords: Coordinates) => void
	element?: MutableRefObject<HTMLElement>
	dependencies?: any[]
	useWindow?: boolean
	throttle?: number
}

/**
 * Fetches the scroll coordinates from the appropriate source
 */
function getScrollCoords(
	useWindow?: boolean,
	element?: MutableRefObject<HTMLElement>
): Coordinates {
	// Use default coordinates for SSR
	if (!isBrowser) return { x: 0, y: 0 }
	// Use window coordinates if requested
	if (useWindow) return { x: window.scrollX, y: window.scrollY }

	// Determine element coordinates; use body if no element is provided
	const target = element ? element.current : document.body
	const elCoords = target.getBoundingClientRect()

	return { x: elCoords.left, y: elCoords.top }
}

/**
 * Intelligently handles scroll position events
 * @param effect The function to call when scroll events occur.
 * Function receives two arguments: New Coordinates and Old Coordinates
 * @param element Optional: An element to track | Defaults to document.body
 * @param dependencies Optional: Array of state variables which trigger component re-render
 * @param useWindow Optional: If true, will use window.scroll[X|Y] coordinates
 * @param throttle Optional: Time, in ms, to throttle events by
 */
export function useScrollPosition({
	effect,
	element,
	dependencies = [],
	useWindow = false,
	throttle = 0
}: ScrollConfig): void {
	const position = useRef(getScrollCoords(useWindow))

	let throttleTimeout: ReturnType<typeof setTimeout> | null = null

	const callback = () => {
		const newCoords = getScrollCoords(useWindow, element)

		// Call effect with new coordinates & previous coordinates
		effect(newCoords, position.current)

		position.current = newCoords
		throttleTimeout = null
	}

	useEffect(() => {
		const handleScroll = () => {
			if (throttle === 0) callback()

			if (throttleTimeout === null)
				throttleTimeout = setTimeout(callback, throttle)
		}

		window.addEventListener('scroll', handleScroll)

		return () => {
			if (throttleTimeout) clearTimeout(throttleTimeout)
			window.removeEventListener('scroll', handleScroll)
		}
	}, dependencies)
}
