import React, {
	useState,
	useRef,
	useEffect,
	ReactNode,
	ReactElement
} from 'react'
import styled from 'styled-components'

//----- Styling -----//

const Outer = styled.div`
	position: relative;
	height: 100%;
`

// Prevent rendering artifacts due to 3D accelleration
const Inner = styled.div`
	transform: translateZ(0);
`

//----- Logic -----//

function getPositions(containerRect: DOMRect, top: number) {
	return {
		relative: {
			width: containerRect.width
		},
		fixed: {
			position: 'fixed',
			left: containerRect.left,
			width: containerRect.width,
			top
		},
		absolute: {
			position: 'absolute',
			top: 'auto',
			left: 0,
			right: 0,
			bottom: 0
		}
	}
}

//----- Component -----//

interface StickySidebarProps {
	topOffset?: number
	children: ReactNode
}

export function StickySidebar(props: StickySidebarProps): ReactElement {
	const [stickyPosition, setStickyPosition] = useState({})

	const { topOffset = 160 } = props

	const outerRef = useRef<HTMLDivElement>(null)
	const innerRef = useRef<HTMLDivElement>(null)

	function updatePosition() {
		if (!outerRef?.current || !innerRef?.current) return

		const outerRect = outerRef.current.getBoundingClientRect()
		const innerRect = innerRef.current.getBoundingClientRect()

		const position = getPositions(outerRect, topOffset)

		// Is the article's content smaller than the sidebar's content?
		if (outerRect.height <= innerRect.height)
			return setStickyPosition(position.relative)

		// Has the user scrolled to the point where the sidebar would overflow the footer?
		if (outerRect.bottom - topOffset <= innerRect.height)
			return setStickyPosition(position.absolute)

		// Has the user scrolled past the point of sticky behavior?
		if (outerRect.top < topOffset) return setStickyPosition(position.fixed)

		return setStickyPosition(position.relative)
	}

	useEffect(() => {
		window.addEventListener('scroll', updatePosition)
		window.addEventListener('resize', updatePosition)

		updatePosition()

		return () => {
			window.removeEventListener('scroll', updatePosition)
			window.removeEventListener('resize', updatePosition)
		}
	}, [])

	return (
		<Outer ref={outerRef}>
			<Inner ref={innerRef} style={stickyPosition}>
				{props.children}
			</Inner>
		</Outer>
	)
}
