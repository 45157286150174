import styled from 'styled-components'
import { lighten, transparentize as fade } from 'polished'

import { Anchor } from '~/components/atoms'
import { AnchorPropsAny } from '~/components/atoms/Anchor/Anchor_types'

interface ButtonProps extends AnchorPropsAny {
	inverted?: boolean
	invertedBorder?: boolean
	slim?: boolean
	extraSlim?: boolean
}

export const Button_CalloutLink = styled(Anchor)<ButtonProps>`
	display: inline-block;
	padding: ${(p) => {
		if (p.slim) return '0.75em 1.2em 0.65em'
		if (p.extraSlim) return '0.55em 1.2em 0.45em'
		return '1em 1.2em 0.9em'
	}};

	font-weight: ${(p) => p.theme.font.sans.weight.bold};
	color: ${(p) =>
		p.inverted
			? p.theme.color.primary.base
			: lighten(0.1, p.theme.color.primary.contrast)};
	line-height: 1.1;
	text-transform: uppercase;

	transition: color 300ms ease-in-out, border 300ms ease-in-out,
		box-shadow 300ms ease-in-out, padding 450ms ease-in-out;
	border: 3px solid
		${(p) =>
			p.invertedBorder
				? p.theme.color.primary.base
				: p.theme.color.primary.accent};

	&:hover,
	&:active,
	&:focus {
		box-shadow: 0 0 10px 0px ${(p) => fade(0.6, p.theme.color.primary.accent)},
			0 0 3px 0px ${(p) => fade(0.5, p.theme.color.primary.contrast)};
		text-decoration: none;
	}

	&:active {
		background-color: ${(p) =>
			fade(0.6, lighten(0.3, p.theme.color.primary.accent))};
	}
`
