import { ReactElement, ReactNode, useEffect } from 'react'

import { UserStateData, useUserContext } from '~/contexts'

//----- Component -----//

export const UserHydrator = (props: {
	children: ReactNode
	user: UserStateData | null
}): ReactElement => {
	const userCtx = useUserContext()

	useEffect(() => {
		if (props.user) userCtx.hydrate(props.user)
	}, [props.user])

	return <>{props.children}</>
}
