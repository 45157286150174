export const categories = Object.freeze([
	Object.freeze({
		name: 'The Sciences',
		slug: 'the-sciences',
		visible: true
	}),
	Object.freeze({
		name: 'Mind',
		slug: 'mind',
		visible: true
	}),
	Object.freeze({
		name: 'Technology',
		slug: 'technology',
		visible: true
	}),
	Object.freeze({
		name: 'Health',
		slug: 'health',
		visible: true
	}),
	Object.freeze({
		name: 'Environment',
		slug: 'environment',
		visible: true
	}),
	Object.freeze({
		name: 'Planet Earth',
		slug: 'planet-earth',
		visible: true
	}),
	Object.freeze({
		name: 'Lifestyle',
		slug: 'lifestyle',
		visible: true
	}),
	Object.freeze({
		name: 'Uncategorized',
		slug: 'uncategorized',
		visible: false
	}),
	Object.freeze({
		name: 'Sponsored',
		slug: 'sponsored',
		visible: false
	})
])
