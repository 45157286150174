import { useEffect, useState, ReactElement } from 'react'
import { AdSlot, DFPManager } from 'react-dfp'
import styled, { CSSProperties } from 'styled-components'

import { SiteConfig } from '~/config'
import { usePageContext } from '~/contexts'

//----- Styling -----//

const AdSlotWrapper = styled.div<{ showBg?: boolean; minHeight?: number }>`
	${(p) =>
		p.showBg &&
		`
		padding: 16px;
		background: ${p.theme.color.gradients.diagonalGray};
		`}

	min-height: ${(p) => (p.minHeight || 1) + 16 * 2}px;
	text-align: center;
`

//----- Util -----//

// Determines whether the user's screen width is within the allowed
// visibility range, as defined by the hideBelow & hideAbove props
const isInViewRange = (hideBelow?: number, hideAbove?: number) => {
	const width = Math.max(
		document.documentElement.clientWidth,
		window.innerWidth || 0
	)

	if (hideBelow && width < hideBelow) return false
	if (hideAbove && width > hideAbove) return false

	return true
}

//----- Component -----//

interface AdUnitProps {
	position: keyof typeof SiteConfig.ads.unit
	hideAbove?: number
	hideBelow?: number
	showBg?: boolean
	className?: string
	style?: CSSProperties
}

/**
 * Findss the smallest height from the list of ad sizes
 */
function getMinHeight(sizes: readonly (readonly number[])[]): number {
	return Math.min(...sizes.map((size) => size[1]))
}

export const AdUnit = (props: AdUnitProps): ReactElement => {
	const { hideBelow, hideAbove, position } = props

	const pageContext = usePageContext()

	const [isVisible, setIsVisible] = useState(false)

	const adUnit = SiteConfig.ads.unit[position]
	if (!adUnit) throw new Error('AdUnit position is not configured')

	const minAdHeight = getMinHeight(adUnit.sizes)

	function checkVisibility() {
		setIsVisible(isInViewRange(hideBelow, hideAbove))
	}

	useEffect(() => {
		// Ads are not available during server-side rendering, so we need to
		// explicitly trigger them here after loading on the client side
		DFPManager.refresh(position)
		checkVisibility()

		window.addEventListener('resize', checkVisibility)

		// Refresh every 30 seconds, if configured
		const refreshAd = adUnit.shouldRefresh
			? window.setInterval(() => {
					if (isInViewRange(hideBelow, hideAbove)) DFPManager.refresh(position)
			  }, 30000)
			: false

		return () => {
			window.removeEventListener('resize', checkVisibility)
			if (refreshAd) window.clearInterval(refreshAd)
		}
	}, [])

	return (
		<>
			{isVisible && (
				<AdSlotWrapper {...props} minHeight={minAdHeight}>
					<AdSlot
						slotId={props.position}
						dfpNetworkId={SiteConfig.ads.networkId}
						adUnit={adUnit.name}
						sizes={adUnit.sizes}
						targetingArguments={{
							path: pageContext.path,
							AdvertisingPageID: pageContext.adTargetingId
						}}
					/>
				</AdSlotWrapper>
			)}
		</>
	)
}
