// The paGlobal object originates from a 3rd-party embedded script, and may possibly not exist
declare const paGlobal:
	| { setKeyValue?: (key: string, value: string | null) => void }
	| undefined

/**
 * Attempt to set targeting values; return boolean indicating success
 */
function trySetValues(
	pagePath?: string | null,
	targetingId?: string | null
): boolean {
	// We cannot set the key value if the paGlobal object does not exist
	if (
		typeof paGlobal === 'undefined' ||
		typeof paGlobal.setKeyValue !== 'function'
	) {
		return false
	}

	paGlobal.setKeyValue('path', pagePath || null)
	paGlobal.setKeyValue('AdvertisingPageID', targetingId || null)

	return true
}

/**
 * Updates NextMillennium ad targeting arguments
 * @param pagePath Current page path
 * @param targetingId Optional targeting ID
 */
export function setAdTargets(
	pagePath?: string | null,
	targetingId?: string | null
): void {
	// Retry for 5 seconds, then stop retrying
	let tryCount = 0
	const tryInterval = 50
	const maxTries = (5 * 1000) / tryInterval

	// Retry setting values until successful or the maximum try duration has expired
	const valueSuccess = setInterval(() => {
		tryCount++
		if (trySetValues(pagePath, targetingId) || tryCount >= maxTries)
			clearInterval(valueSuccess)
	}, tryInterval)
}
