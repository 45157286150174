import { ReactElement } from 'react'

import { Image } from '~/components/atoms'

// TODO: This placeholder URL should be pulled dynamically from the CFL Website entry
const PLACEHOLDER_URL =
	'//images.ctfassets.net/cnu0m8re1exe/2OEmffb1iiLzWahqOjuv36' +
	'/f67649628d500ecfb0a81791f4a103ec/placeholder.jpg'

//----- Logic -----//

/**
 * Images coming from Contentful's "downloads" server are not mutable
 */
function isMutableAsset(src: string): boolean {
	return !src?.includes('downloads.ctfassets.net')
}

/**
 * Converts all images to progressive jpeg and resizes according to arguments.
 */
function getQueryString(
	changeFormat: boolean,
	maxWidth?: number,
	maxHeight?: number,
	resizeMethod = 'fill'
) {
	const query: string[] = []

	if (changeFormat) {
		query.push('fm=jpg')
		query.push('fl=progressive')
	}

	if (maxWidth || maxHeight) {
		if (maxWidth) query.push(`w=${maxWidth}`)
		if (maxHeight) query.push(`h=${maxHeight}`)
		query.push(`fit=${resizeMethod}`)
	}

	return query.length ? `?${query.join('&')}` : ''
}

//----- Component -----//

interface ImageProps extends React.ComponentProps<typeof Image> {
	resizeMethod?: 'pad' | 'fill'
}

export const Image_Contentful = (props: ImageProps): ReactElement => {
	const { src: rawSrc, resizeMethod, ...imgProps } = props

	// Use a fallback placeholder image if the supplied src is immutable
	const imageSrc = isMutableAsset(rawSrc) ? rawSrc : PLACEHOLDER_URL
	const queryString = getQueryString(
		!imageSrc.endsWith('gif'), // Don't reformat gif images
		props.maxWidth,
		props.maxHeight,
		resizeMethod
	)

	const src = `${imageSrc}${queryString}`

	return <Image src={src} {...imgProps} />
}
